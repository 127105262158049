import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NovaPoshta from 'novaposhta';
import { useCookies } from 'react-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from "swiper";
import { Navigation } from "swiper";
import { QuantityPicker } from 'react-qty-picker';
import axios from 'axios';
import logo from "../../assets/images/logo.svg";
import book_flag from "../../assets/images/book_flag.jpg";
import book from "../../assets/images/book.png";
import arrow from "../../assets/images/Arrow.png"
import "swiper/css";
import "swiper/css/scrollbar";
import "./index.scss"
import { API } from '../../config';

const Home = () => {

    const [cookies, setCookie] = useCookies(["formPhone", "formName", "formObl", "formCity", "formVid", "statusSend", "invoiceId", "PayUrl"]);

    const [show, setShow] = useState(false);

    const [formName, setformName] = useState(cookies.formName ? cookies.formName : '');
    const [formPhone, setformPhone] = useState(cookies.formPhone ? cookies.formPhone : '');
    const [formObl, setformObl] = useState(cookies.formObl ? cookies.formObl : '');
    const [formCity, setformCity] = useState(cookies.formCity ? cookies.formCity : '');
    const [formVid, setformVid] = useState(cookies.formVid ? cookies.formVid : '');
    const [formNextDisable, setformNextDisable] = useState(true);
    const [formQnt, setformQnt] = useState(1);
    setCookie('statusSend', false, { path: '/' });
    const [formOblData, setformOblData] = useState([]);
    const [formCityData, setformCityData] = useState([]);
    const [formVidData, setformVidData] = useState([]);
    const [swiperSlide, setSwiperSlide] = useState({});
    const saveFormData = () => {
        setCookie('formName', formName, { path: '/' });
        setCookie('formPhone', formPhone, { path: '/' });
        setCookie('formObl', formObl, { path: '/' });
        setCookie('formCity', formCity, { path: '/' });
        setCookie('formVid', formVid, { path: '/' });
    }
    const clearForm = () => {
        setCookie('formName', "", { path: '/' });
        setCookie('formPhone', "", { path: '/' });
        setCookie('formObl', "", { path: '/' });
        setCookie('formCity', "", { path: '/' });
        setCookie('formVid', "", { path: '/' });
        setformName("");
        setformPhone("");
        setformObl("");
        setformCity("");
        setformVid("");
        setformCityData([]);
        setformVidData([]);

    }
    const handleChangeFormName = (event) => {
        setformName(event.target.value);
        saveFormData();

    };
    const goPay = () => {
        saveFormData();
        swiperSlide.slideNext()
    }
    const handleChangeFormPhone = (event) => {
        setformPhone(event.target.value);
        saveFormData();


    };

    const handleChangeFormObl = (event) => {
        setformObl(event.target.value);
        setformCityData([])
        setformVidData([])
        saveFormData();

    };

    const handleChangeFormCity = (event) => {
        setformCity(event.target.value);
        setformVidData([])
        saveFormData();

    };

    const handleChangeFormVid = (event) => {
        setformVid(event.target.value);
        saveFormData();

    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 400,
            },
        },
    };
    useEffect(() => {
        if (!formName || !formCity || !formObl || !formVid || !formPhone) {
            setformNextDisable(true)
        } else {
            setformNextDisable(false)
        }



    }, [formCity, formName, formPhone, formObl, formVid])

    useEffect(() => {
        axios.get(API.LevensAPI + "/getObl")
            .then((response) => {
                setformOblData(response.data)
            }).catch(

        );

    }, [formObl]);


    useEffect(() => {
        if (formObl) {
            axios.get(API.LevensAPI + "/getCity?Ref=" + (formObl.split('|'))[0])
                .then((response) => {
                    setformCityData(response.data)
                }).catch(

            );
        }

    }, [formObl]);

    useEffect(() => {
        if (formCity) {
            axios.get(API.LevensAPI + "/getVid?Ref=" + (formCity.split('|'))[0])
                .then((response) => {
                    console.log(response.data)
                    setformVidData(response.data)
                }).catch(

            );
        }
    }, [formCity]);

    const d = new Date();
    const datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
    const template = `-------Заявка на ${datestring}------- \n<b>Ім'я:</b> ${formName} \n<b>Телефон:</b> ${formPhone} \n-------ДОСТАВКА------- \n\n<b>Місто:</b> ${(formCity.split('|'))[1]} \n<b>Відділення:</b> ${(formVid.split('|'))[1]} \n<b>Кількість:</b> ${formQnt} шт \n\n<b>Сума оплати:</b> ${250 * formQnt} грн`;

    const payMono = () => {
        axios.get(API.LevensAPI + `/createMono?qty=${formQnt}&text=${encodeURIComponent(template)}`)
            .then((response) => {
                window.location.replace(response.data.pageUrl);
                setCookie('invoiceId', response.data.invoiceId, { path: '/' });
                setCookie('PayUrl', response.data.pageUrl, { path: '/' });
                
            }).catch(

        );
    }

    return (<>
        <div id="page-wrap">
            <div className="book">
                <div className="container">
                    <div className="book__row">
                        <div className="book__text">
                            <a href="/" className="book__logo"><img src={logo} alt="levens" /></a>
                            <div className="page_title">Українець і&nbsp;москвин</div>
                            <div className="book__subtitle">Книга, що відкриває очі щодо відносин двух націй.</div>
                            <div className="book__note">Придбавши книжку ти підтримуєш українського виробника та зберігаєш робочі місця.</div>
                            <button className="book__buy_btn toggle-handler" data-toggle-target="#book_popup" onClick={handleShow}>Перейти до сплати книги</button>
                        </div>
                        <div className="book__img">
                            <img src={book_flag} alt="" className="book__img-bg" />
                            <img src={book} alt="" className="book__img-book" width="419" height="729" />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body>
                    <button className="book_popup__close_btn toggle-handler" onClick={handleClose}></button>
                    <Swiper
                        allowTouchMove={false}
                        scrollbar={{
                            hide: true,
                            draggable: false,
                            enabled: false
                        }}
                        slidesPerView={1}
                        onInit={(ev) => {
                            setSwiperSlide(ev)
                        }}
                        modules={[Scrollbar, Navigation]}
                        navigation={{
                            nextEl: '.form_menu_next',
                            prevEl: '.form_menu_prev',
                        }}
                    >
                        <SwiperSlide>
                            <div className='container'>
                                <div className='text-center'><h3>Оформити замовлення</h3></div>
                                Контакти
                                <div className='row'>

                                    <div className='col-lg'>
                                        <TextField id="filled-basic" value={formName} onChange={handleChangeFormName} className='imput-contact' label="Ім’я та прізвище" variant="outlined" />
                                    </div>
                                    <div className='col col-lg'>

                                        <TextField id="filled-basic" value={formPhone} onChange={handleChangeFormPhone} className='imput-contact' label="Номер телефону" variant="outlined" />
                                    </div>
                                </div>
                                Доставка
                                <div className='row'>
                                    <div className='col-lg'>
                                        <FormControl fullWidth className='imput-contact'>
                                            <InputLabel id="demo-simple-select-label" >Область</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formObl}
                                                label="Область"
                                                onChange={handleChangeFormObl}
                                                MenuProps={MenuProps}
                                                out-of-range={200}
                                            >
                                                {formOblData.map(item => {
                                                    return <MenuItem key={Math.random()} value={item.Ref + "|" + item.Description}>{item.Description}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='col col-lg'>
                                        <FormControl fullWidth className='imput-contact' disabled={formCityData.length === 0 ? true : false}>
                                            <InputLabel id="demo-simple-select-label">Місто</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formCity}
                                                label="Місто"
                                                onChange={handleChangeFormCity}
                                                MenuProps={MenuProps}
                                                out-of-range={200}
                                            >
                                                {formCityData.map(item => {
                                                    return <MenuItem key={Math.random()} value={item.Ref + "|" + item.Description}>{item.Description}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col col-lg'>
                                        <FormControl fullWidth className='imput-contact' disabled={formVidData.length === 0 ? true : false}>
                                            <InputLabel id="demo-simple-select-label">Відділеня НП</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formVid}
                                                label="Відділеня НП"
                                                onChange={handleChangeFormVid}
                                                MenuProps={MenuProps}
                                                out-of-range={200}
                                            >
                                                {formVidData.map(item => {
                                                    return <MenuItem key={Math.random()} value={item.Ref + "|" + item.Description} >{item.Description}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <button className="book_clear_btn book__buy_btn" onClick={() => clearForm()} disabled={formCity || formName || formPhone || formObl || formVid ? false : true}>Очистити</button>
                                {formNextDisable ? <button className="book_buy_btn book__buy_btn" disabled="disabled">Перейти до оплати <img src={arrow} /></button> : <button className="book_buy_btn book__buy_btn" onClick={()=>goPay()}>Перейти до оплати <img src={arrow} /></button>}
                                <br />
                                <br />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide >
                            <div className="container">

                                <div className='text-center'><h3>Оплата</h3></div>
                                <h5>Дані доставки:</h5>
                                <b>Ім'я:</b> {formName}
                                <p /><b>Телефон:</b> +{formPhone}
                                <p /><b>Місто:</b> {(formCity.split('|'))[1]}
                                <p /><b>Відділення:</b> {(formVid.split('|'))[1]}
                                <div className='additional-info'>
                                    <h5 className='bool-header-cart'>Відомості про товар:</h5>
                                    <div className='row'>
                                        <div className='col-lg'>
                                            <b>Назва:</b> Книга "Українець і&nbsp;москвин"
                                            <br />
                                            <br />
                                            <div className='d-flex'>

                                                <p /><b>Кількість:</b> &nbsp;&nbsp;<QuantityPicker min={1} max={5} value={formQnt} onChange={(value) => { setformQnt(value) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="book_clear_btn form_menu_prev book__buy_btn">Назад</button>
                            <button className="book_buy_btn book__buy_btn open_mono" onClick={() => payMono()} >До оплати {(250) * formQnt} грн.</button>
                        </SwiperSlide>
                    </Swiper>
                </Modal.Body>
            </Modal>
        </div>
    </>)
}
export default Home;