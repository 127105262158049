import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import success from "../../assets/images/success-icon.png"
import axios from 'axios';

const Status = () => {

    return (
        <>
            <div class="d-flex align-items-center min-vh-100">
                <div class="container text-center card-staus">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Оплата успішна</h5>
                            <img className='imagestatus' src={success} />
                            <Link to="/">Повернутись на головну</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Status;

