/**
 * @desc Routes for getting cards deck.
 * @const
 * @type {string}
 * @default
 */

/**
 * @desc Routes for the whole app.
 * @const
 * @type {object}
 * @default
 */
 export const ROUTES = {
  root: "/",
  status: "/status"
};

export const API = {
  LevensAPI: "https://bookapi.levens.ua/api/v1"

};


