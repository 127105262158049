
import React from 'react'
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "./config";

import HomePage from "./modules/home";
import Status from './modules/Status';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";



const { root, status} = ROUTES;

/**
 * @desc component "App".
 * @return {JSX.Element} - main component with routing
 */

const App = () => {
  return (
    <Switch>
      <Route exact path={root} component={HomePage} />
      <Route exact path={status} component={Status} />
    </Switch>
  );
};

export default App;
